import gql from "graphql-tag";

export const POST_REQUST = gql`
  mutation postRequest($PartInput: PartInput!, $Location: String!) {
    postRequest(partData: $PartInput, location: $Location) {
      _id
      requester
      name
      manufacturer
      model
      year
      category
      images
      description
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation addAddress($AddressInput: AddressInput!) {
    addAddress(addressInput: $AddressInput) {
      _id
      buildingNo
      streetName
      district
      addNo
      postalCode
      city
    }
  }
`;

export const POST_OFFER = gql`
  mutation postOffer($RequestId: ID!, $OfferData: OfferInput!) {
    postOffer(requestId: $RequestId, offerData: $OfferData) {
      _id
      seller
      offerAmount
      shippingAmount
      acceptsCod
      address {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      
      
      
      insurancePeriod
      comment
      images
    }
  }
`;

export const ADD_USER_INFO = gql`
  mutation updateUserInfo($Fname: String!, $Lname: String!, $Email: String!) {
    updateUserInfo(fname: $Fname, lname: $Lname, email: $Email) {
      _id
      phone
      firstName
      lastName
      isActive
    }
  }
`;

export const SUBMIT_ORDER = gql`
  mutation submitOrder(
    $RequestId: ID!
    $AddressId: ID
    $OfferId: ID!
    $Cod: Boolean!
    $pickUp: Boolean!
    $otp: Int
    $NoonId: String
  ) {
    submitOrder(
      requestId: $RequestId
      addressId: $AddressId
      offerId: $OfferId
      cod: $Cod
      pickUp: $pickUp
      otp: $otp
      noonId: $NoonId
    ) {
      _id
      
      requester {
        _id

        firstName
        lastName
        email
      }
      seller {
        _id

        firstName
        lastName
        email
      }
      shipFrom {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      shipTo {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      part {
        name
        manufacturer
        model
        year
        category
        images
        description
      }
      partPrice
      shippingPrice
    }
  }
`;

export const CANCEL_OFFER = gql`
  mutation cancelOffer(
    $RequestId: ID!
    $OfferId: ID!    
  ) {
    cancelOffer(requestId: $RequestId,offerId: $OfferId)
  }

`;


export const REMOVE_REQUEST = gql`
  mutation removeRequest(
    $RequestId: ID! 
  ) {
    removeRequest(requestId: $RequestId)
  }

`;
