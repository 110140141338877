import "regenerator-runtime";
import { router } from "../_helpers";
import axios from "axios";
import BrowserDetect from "../_helpers/browserDetect.js"; 
export const glopal = {
  namespaced: true,

  state: {
    loginStepN: 0,
    stepNo: 0,
    newRequest: {
      name: null,
      manufacturer: null,
      model: null,
      year: 2010,
      category: null,
      images: [],
      description: null,
    },
    loadingStatusNo: 0,
    ModalState: 0,
    addingAddress: false,
    city: null,
    userType: null,
    selectedAddress: null,
    isLoading: false,
    selectedOffer: null,
    selectedRequest: null,
    userHaveInfo: false,
    userID: null,
    renderComponent: true,
    userRequests: null,
    sellerOffers: null,
    window: {
      width: 0,
      height: 0,
    },
    showModal: false,
  
  },
  mutations: {
    SET_TOKEN(state, token) {
      const now = new Date();

      const expirtionDate = new Date(now.getTime() + 333000);
      console.log(expirtionDate);
      localStorage.setItem("tokenExpirtion", expirtionDate);
      localStorage.setItem("apollo-token", token);
      console.log("token sets");
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = {
        ...state.user,
        accessToken: accessToken,
      };
    },
    LOGIN_USER(state, token) {
      state.token = token;
      let decoded = jwt_decode(token);
      state.userType = decoded.userType;
    },
    LOGOUT_USER(state) {
      state.status.loggedIn = false;
      state.token = "";
      state.loginStepN = 0;
      localStorage.removeItem("apollo-token");
      localStorage.removeItem("tokenExpirtion");
    },
    loadingStatus(state, number, data) {
      state.loadingStatusNo = number;
      state.requests = data;
    },
    AddLocation(state, city) {
      state.city = city;
    },
    editToast(state, { text }) {
      state.ToastText = text;
      if (state.toast == true) {
        state.toast = false;
        return;
      }

      state.toast = true;
    },
    resize(state, { width, height }) {
      state.window.height = height;
      state.window.width = width;
    },
    loginStepNcount(state, number) {
      state.loginStepN = number;
    },
    isLoading(state, status) {
      state.isLoading = status;
    },
  },
  actions: {
    async getAdsress({ state }) {
      const { data } = await client.clients.defaultClient.query({
        query: GET_ADDRESS,
      });

      return data.getAddress;
    },

    async addAdsress({ state, dispatch }, address) {
      let newAddress = {
        AddressInput: address,
      };

      const { data } = await client.clients.defaultClient.mutate({
        mutation: ADD_ADDRESS,
        variables: {
          ...newAddress,
        },
      });

      state.selectedAddress = data.addAddress[data.addAddress.length - 1];
      console.log(data);

      return data.addAddress[data.addAddress.length - 1];
    },

    async postOffer({ state, commit }, offer) {
      state.isLoading = true;
      console.log(offer);
      const Offer = {
        RequestId: offer.RID,
        OfferData: {
          offerAmount: offer.offerIn.offerAmount,
          shippingAmount: offer.offerIn.shippingAmount,
          acceptsCod: offer.offerIn.acceptsCod,
          acceptsCollect: offer.offerIn.acceptsColl,
          addressId: offer.address,
          images: offer.offerIn.images,
          comment: offer.offerIn.comment,
          insurancePeriod: offer.offerIn.insurancePeriod,
          codCharge: offer.offerIn.codCharge,
        },
      };
      try {
        const { data } = await client.clients.defaultClient.mutate({
          mutation: POST_OFFER,
          variables: {
            ...Offer,
          },
        });
        console.log(data);

        commit("editToast", {
          text: "تم إضافة العرض",
        });
        state.isLoading = false;
        //window.location.reload(true);
      } catch (e) {
        commit("editToast", {
          text: e,
        });
      }

      return;
    },
    async updateUserInfo({ state }, UserInfo) {
      const { data } = await client.clients.defaultClient.mutate({
        mutation: ADD_USER_INFO,
        variables: {
          ...UserInfo,
        },
      });
      return data;
    },

    async sumbitOrder({ state, commit, dispatch }, order) {
      try {
        const { data } = await client.clients.defaultClient.mutate({
          mutation: SUBMIT_ORDER,
          variables: {
            ...order,
          },
        });
        //  commit("editToast", { text: "شكرا لك على طلبك من سكرابيز" });
        dispatch("loadRequests");

        return data;
      } catch (e) {
        commit("editToast", {
          text: e,
        });
      } finally {
      }
    },
    async getOrders({ state, commit }) {
      const { data } = await client.clients.defaultClient.query({
        query: GET_ORDERS,
      });

      return data.getOrders;
    },
    async loadFullOrder({ state, commit, dispatch }, ID) {
      let OrderID = {
        OrderID: ID,
      };
      const { data } = await client.clients.defaultClient.query({
        query: GET_FUUL_ORDER,
        variables: {
          ...OrderID,
        },
      });

      console.log(data);

      return data.getOrder;
    },
    async loadFullOrder({ state, commit, dispatch }, ID) {
      let OrderID = {
        OrderID: ID,
      };
      const { data } = await client.clients.defaultClient.query({
        query: GET_FUUL_ORDER,
        variables: {
          ...OrderID,
        },
      });

      console.log(data);

      return data.getOrder;
    },
    async getOffers({ state, commit }) {
      const { data } = await client.clients.defaultClient.query({
        query: GET_OFFERS,
      });

      return data;
    },
    async getUserInfo({ state }) {
      const { data } = await client.clients.defaultClient.query({
        query: GET_USER_INFO,
      });
      console.log(data.getUserInfo);
      if (data.getUserInfo.firstName) {
        state.userHaveInfo = true;
        return true;
      }
      return;
    },
  },
  getters: {
    LoadStepNo: (state) => state.stepNo,
    imagesUrl: (state) => state.partImagesUrl,
    selectedAddress: (state) => state.selectedAddress,
    isLoading: (state) => state.isLoading,
    IsUserHaveInfo: (state) => state.userHaveInfo,
    renderComponent: (state) => state.renderComponent,
    userRequests: (state) => state.userRequests,
    loginStepN: (state) => state.loginStepN,
    showModal: (state) => state.showModal,
    window: (state) => state.window,
    UserBrowser(){
      return BrowserDetect()
    }
  },
};
