<template>
  <div class="container text-center">
    <form @submit.prevent="submit" class="text-center">
      <h3 class="my-5">دخل بيانات القطعة</h3>

      <div class="row">
        <input
          type="text"
          class="form-group bwj-w form-control"
          placeholder="القسم"
          list="category"
          v-model.trim="$v.category.$model"
          :class="{ 'is-invalid': submitted && $v.category.$error }"
        />

        <datalist id="category">
          <option v-for="category in categories">{{ category }}</option>
        </datalist>

        <div v-if="submitted && $v.category.$error" class="invalid-feedback">
          <span v-if="!$v.category.required">هذا الحقل مطلوب</span>
        </div>
      </div>

      <div class="row">
        <input
          type="text"
          class="form-group bwj-w form-control"
          placeholder="اسم القطعة"
          v-model.trim="$v.partName.$model"
          :class="{ 'is-invalid': submitted && $v.partName.$error }"
        />
        <div v-if="submitted && $v.partName.$error" class="invalid-feedback">
          <span v-if="!$v.partName.required">هذا الحقل مطلوب</span>
        </div>
        <span class="mx-auto mb-2 text-danger" style="font-size:small">يفضل رفع طلب مستقل لكل قطعة لضمان الحصول على عرض</span>
      </div>

      <div class="row">
        <input
          type="text"
          class="form-group bwj-w form-control"
          placeholder="وصف القطعة..."
          style="height: 8rem !important"
          v-model="description"
        />
      </div>

      <div class="row">
        <label
          for="file-upload"
          class="form-control border border-success"
          style="font-size: 1.5em"
        >
          أرفع صور للقطعة
          <i class="camera icon"></i>
        </label>

        <input
          @change="setImage"
          id="file-upload"
          type="file"
          accept="image/*"
          multiple
          :class="{ 'is-invalid': submitted && $v.images.$error }"
        />
        <div v-if="submitted && $v.images.$error" class="invalid-feedback">
          <span class="error" v-if="!$v.images.required"
            >يجب ان ترفع صورة على الأقل</span
          >
        </div>
        <span>عدد الصور{{ images.length }}</span>

        <i
          v-if="images.length > 0"
          class="trash alternate outline icon text-success"
          @click="images = []"
        ></i>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Compressor from "compressorjs";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
    reader.onerror = (error) => reject(error);
  });
}

export default {
  data() {
    return {
      submitted: false,

      category: null,
      partName: null,

      description: null,
      categories: [
        "نظام الدفع",
        "المقصورة الداخلية",
        "المكينة",
        "الهيكل الخارجي والبدي",
        "الأجزاء الكهربائية",
        "نظام المكابح",
        "أخرى",
      ],
      images: [],
    };
  },
  validations: {
    category: {
      required,
    },
    partName: {
      required,
    },

    images: {
      required,
    },
  },
  methods: {
    submmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
        return;
      } else {
        this.$store.state.glopal.newRequest.category = this.category;
        this.$store.state.glopal.newRequest.name = this.partName;
        this.$store.state.glopal.newRequest.description = this.description;
        this.$store.state.glopal.newRequest.images = this.images;
        if (this.$store.getters["authentication/isAuthenticated"] == true) {
          this.$store.state.glopal.ModalState = 2;
          return;
        }
        this.$store.state.glopal.stepNo = 2;
      }
    },
    async setImage(event) {
      let compressedImages = [];

      for (const key of event.target.files) {
        const image = await new Compressor(key, {
          quality: 0.6,
          strict: true,

          height: 560,
          convertSize: 200000,
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            getBase64(result).then((res) => {
              let ImageFile = { fileName: result.name, fileBody: res };
              compressedImages.push(ImageFile);
              console.log(compressedImages);
            });
          },
          error(err) {
            console.log(err.message);
          },
        });
      }
      this.images = compressedImages;
      console.log(this.images);
      this.$v.images.$touch();
    },
  },
};
</script>

<style scoped>
h1,
p {
  text-align: center;
}

p {
  color: red;
}

img.preview {
  width: 200px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

input[type="file"] {
  display: none;
}

.custom-file-input::-webkit-file-upload-text {
  visibility: hidden;
}

.custom-file-input::before {
  visibility: visible;
  content: "Upload Your Image";
  -webkit-user-select: none;
  cursor: pointer;
}
</style>
