<template>
  <div>
    <footer
      class="py-4 "
      style=" box-shadow: 0 -.125rem .25rem rgba(0,0,0,.075)!important;"
    >
      <div class="container text-right">
        <div class="footer row justify-content-around">
          <div class="col-md-auto  ">
            <div><a href="" id="Bawaji_slagon_footer">سكرابيز</a></div>

            <div>
              <img src="../../img/mada.jpg" width="30rem" height="30rem" />
              <img src="../../img/visa.png" width="30rem" height="30rem" />
              <img
                src="../../img/mastercard.png"
                width="30rem"
                height="30rem"
              />
              <img src="../../img/applePay.png" width="30rem" height="30rem" />
              
            </div>
          </div>

          <div class="col-md-auto  mt-2">
            <ul class="list-unstyled  font-weight-bold text-right">
              <!-- <router-link to="ContactUs" tag='li'><a>- تواصل معنا</a></router-link> -->
              <router-link to="ShippingAndPayment" tag="li"
                ><a>- الشحن والدفع</a></router-link
              >
              <router-link to="canclePage" tag="li"
                ><a>-سياسة الإلغاء والاسترجاع والاستبدال </a></router-link
              >
              <router-link to="ContactUs" tag="li"
                ><a>-تواصل معنا </a></router-link
              >
            </ul>
          </div>
          <div class="col-md-auto  ">
            <ul class="list-unstyled  font-weight-bold text-right">
              <router-link to="About" tag="li"
                ><a>- عن سكرابيز</a>
              </router-link>
              <router-link to="CommonQuestions" tag="li"
                ><a>- الأسئلة الشائعة</a>
              </router-link>
              <router-link to="PrivacyPolicy" tag="li"
                ><a>- سياسة الخصوصية</a>
              </router-link>
            </ul>
          </div>

          <div class="col-md-auto  ">
            <a
              href="https://api.whatsapp.com/send?phone=966501965909"
              target="_blank"
            >
              <img
                id="whatsapp"
                class="socialmedea"
                src="../../img/whatsapp.png"
                width="36px"
                height="36px"
            /></a>
            <a href="https://twitter.com/scrapiz_sa" target="_blank">
              <img
                id="twitter"
                class=" mx-2 socialmedea"
                src="../../img/twitter.png"
                width="36px"
                height="36px"
            /></a>
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <h6 class="col-md-auto col-sm-12 ">
            مؤسسة المركبة للتسويق الالكتروني
          </h6>
          <h6 class="col-md-auto col-sm-12 " style="direction:ltr">جميع الحقوق محفوظة {{year}} </h6>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed:{
    year(){
       let d = new Date();
       console.log(d.getFullYear());
       return d.getFullYear()

    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.list-unstyled {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
</style>
