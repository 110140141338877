import axios from "axios";
import { authHeader, setToken } from "../_helpers";



export const userService = {
  login,
  logout,
  refreshToken,
};
let token = null;
const httpEndpoint =
  process.env.NODE_ENV == "development"
    ? " http://localhost:3000"
    : "https://scrapiz.com";
console.log(process.env.NODE_ENV);
function login(authDetails) {
  console.log(httpEndpoint);
  return axios.post(httpEndpoint + "/user/login", authDetails, {
      withCredentials: true,
    })
    .then((user) => {
      console.log(user);
     
     // onLogin();
      return setToken(user);
    })
    .catch((error) => {
      console.log(error.response);
      return Promise.reject(error.response.data.message);
    })
}

function logout() {

  localStorage.removeItem("user");
}

function refreshToken() {
  return new Promise(function(resolve, reject) {
    resolve(
      axios.post(httpEndpoint + "/user/refresh-access-token", null, {
        withCredentials: true,
      })
    );
  })
    .then(function(user) {
      // (**)

      console.log(user);

      return setToken(user);
    })
    .catch((error) => {
      console.log(error);

      return Promise.reject(error);
    });
}
