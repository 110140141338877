import { userService } from "../_services";
import { router } from "../_helpers";

const httpEndpoint =
  process.env.NODE_ENV == "development"
    ? " http://localhost:8080"
    : "https://scrapiz.com";
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { user: { loggedIn: true, userType: user.userType } }
  : { user: { loggedIn: false, userType: null } };
export const authentication = {
  namespaced: true,
  state: { user: initialState.user },
  actions: {
    async login({ state, dispatch, commit }, authDetails) {
      console.log(authDetails);
      const data = userService.login(authDetails).then(
        (user) => {
          console.log(user);
          // dispatch("setLougoutTimer");
          commit("loginSuccess", user);

          return Promise.resolve(user.userType);
        },
        (error) => {
          commit("loginFailure", error);
          dispatch("alert/error", error, { root: true });
          return Promise.reject(error);
        }
      );
      return data;
    },
    logout({ commit, rootState }) {
      // console.log(rootState);
      userService.logout();
      commit("logout");
      console.log(router.currentRoute);
      if (router.currentRoute=='404') {
        router.replace("/login");
      }
      
      rootState.glopal.isLoading=false;

      
    },
    async refreshToken({ commit, dispatch }) {
      console.log("refresh");
      const data = await userService
        .refreshToken()
        .then((refreshResponse) => {
          console.log(refreshResponse.token);
          commit("refreshToken");
          return Promise.resolve(refreshResponse.token);
        })
        .catch((error) => {
          console.log(error);
          dispatch("logout");
          
          return Promise.reject(error);
        });

      return data;
    },
  },
  mutations: {
    loginSuccess(state, user) {
      console.log(user);
      state.user.userType = user.userType;
      state.user.loggedIn = true;
    },
    loginFailure(state) {
      state.user.userType = null;
      state.user.loggedIn = false;
    },
    logout(state) {
      state.user.userType = null;
      state.user.loggedIn = false;
    },
    refreshToken(state) {
      state.user.loggedIn = true;
    },
  },
  getters: {
    isAuthenticated: (state) => state.user.loggedIn,
    userType: (state) => state.user.userType,
  },
};
