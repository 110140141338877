let categories= [
  "نظام الدفع",
  "المقصورة الداخلية",
  "المكينة",
  "الهيكل الخارجي والبدي",
  "الأجزاء الكهربائية",
  "نظام المكابح",
  "أخرى",
];

export function getCatrgories() {
  return  categories.map((x, index) => {
    return {
      id: index,
      label: x,
    };
  })
}

export function getFillterdCatrgories(ids) {
  let categoryArr = getCatrgories();
  let filterdCatrgories = [];

  for (const key in ids) {
    
    filterdCatrgories.push(categoryArr[parseInt(ids[key])].label);
  }

  return filterdCatrgories
}
