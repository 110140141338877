<template>
  <div class="text-center">
    <form @submit.prevent="submit" class="text-center">
      <h3 class="my-5">وين تبغى توصلك القطعة</h3>

      <div class="row">
        <input
          class="form-group bwj-w form-control"
          type="text"
          v-model.trim="$v.city.$model"
          list="cities"
          placeholder="المدينة"
          :class="{ 'is-invalid': submitted && $v.city.$error }"
        />
        <datalist id="cities">
          <option v-for="city in cities">{{ city.A }}</option>
        </datalist>
        <div v-if="submitted && $v.city.$error" class="invalid-feedback">
          <span class="error" v-if="!$v.city.required">هذا الحقل مطلوب</span>
        </div>
      </div>
    </form>
    <button
      class="btn bwj-btn btn-lg"
      @click="submitLocation"
      :disabled="loading"
    >
      التالي
    </button>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import json from "../assets/cities.json";
import { POST_REQUST } from "../graphql/mutations";
import { GET_USER_REQUSTS } from "../graphql/queries";
import moment from "moment";

export default {
  data() {
    return {
      city: null,
      cities: json,
      submitted: false,
      loading: false,
      RequestFilter: {
        skip: 0,
        limit: this.$store.state.glopal.window.width < 992 ? 10 : 12,
      },
    };
  },
  validations: {
    city: {
      required,
    },
  },
  methods: {
    submitLocation() {
      this.submitted = true;
      this.$v.$touch();
      this.loading = true;
      if (this.$v.$invalid) {
        console.log("invalid");
        this.loading = false;
        return;
      } else {
        let request = {
          PartInput: this.$store.state.glopal.newRequest,
          Location: this.city,
        };
        console.log(request);

        this.$apollo
          .mutate({
            // Query
            mutation: POST_REQUST,
            // Parameters
            variables: {
              PartInput: this.$store.state.glopal.newRequest,
              Location: this.city,
            },
            // Update the cache with the result
            // The query will be updated with the optimistic response
            // and then with the real result of the mutation
            update: (store, { data: { postRequest } }) => {
              console.log(store);
              var date = new Date();
              date.setDate(date.getDate() + 5);
              var dayWrapper = moment(date);

              postRequest.expiresAt = dayWrapper.valueOf();

              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: GET_USER_REQUSTS,
                variables: {
                  RequestsFilter: this.RequestFilter,
                },
              });

              console.log(data);
              postRequest["offers"] = [];
              data.getUserRequests.requests.splice(0, 0, postRequest);
              console.log(data.getUserRequests);

              store.writeQuery({
                query: GET_USER_REQUSTS,
                data,
              });
            },
          })
          .then((data) => {
            // Result
            this.loading = false;
            this.$store.commit("glopal/isLoading", false);
            if (this.$router.currentRoute.path != "/Requests") {
              this.$router.replace("/Requests");
            }
            this.$store.state.glopal.ModalState = 0;
            this.$store.state.glopal.stepNo = 0;
            $("html, body").animate({ scrollTop: 0 }, "slow");
            this.$store.dispatch(
              "alert/success",
              "تم اضافة تسعيرة جديدة\n *ملاحظة: سيتم حذف التسعيرة آليا بعد مرور 5 أيام"
            );

            this.$store.state.glopal.loginStepN = 0;
            console.log("here");
          })
          .catch((error) => {
            // Error
            this.loading = false;
            this.$store.commit("glopal/isLoading", false);
            console.error(error);
          });
      }
    },
  },
};
</script>

<style></style>
