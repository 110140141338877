<template>
<div>


  <nav class="navbar navbar-expand-lg navbar-light px-4 shadow-sm d-flex">

    <button v-if="isAuthenticated" class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <img role="button" :class="{'bwj-nav':isAuthenticated}" tabindex="0" src="../../img/Bawaji.svg" width="100vh" height="60vh" @click="route">
    <div class="d-flex align-items-center ml-md-5 " v-if="smallScreen">
      <div class="d-flex align-items-center ">

        <router-link to="login" tag='button' class='nav-item btn btn-sm bwj-btn my-2 my-sm-0' v-if="!isAuthenticated">سجل دخولك
        </router-link>
      </div>
    </div>
    <div class="collapse navbar-collapse navbarSupportedContent justify-content-start mr-sm-5">
      <ul class="navbar-nav bwj-nav" style="margin-right:-2rem!important;">

        <li class='nav-item' v-if=" isAuthenticated && userType=='Buyer' ">
          <router-link to="/Requests" class="nav-link"> تسعيراتي </router-link>
        </li>
        <li class='nav-item' v-if=" isAuthenticated && userType=='Buyer' ">
          <router-link to="/Orders" class="nav-link"> طلباتي </router-link>
        </li>

        <li class='nav-item' v-if=" isAuthenticated && (userType=='Seller' || userType=='Administrator' ) ">
          <router-link to="/Seller/Requests" class="nav-link"> طلبات التسعير </router-link>
        <li class='nav-item' v-if=" isAuthenticated && userType=='Seller' ">
          <router-link to="/Seller/Offers" class="nav-link"> العروض </router-link>
        <li class='nav-item' v-if=" isAuthenticated && userType=='Seller' ">
          <router-link to="/Seller/Orders" class="nav-link"> الطلبات </router-link>
        </li>




      </ul>



    </div>
    <div class="aboutSetting align-items-center  justify-content-md-end ml-2" v-if="!smallScreen">

      <router-link to="login" tag='button' class='nav-item btn bwj-btn my-2 my-sm-0' v-if="!isAuthenticated"> سجل دخولك </router-link>
    </div>

    <div class="collapse navbar-collapse  navbarSupportedContent   justify-content-sm-start justify-content-md-end mr-md-5" v-if="isAuthenticated ">

      <ul class="navbar-nav  bwj-nav " style="margin-right:-2rem!important;">
        <li class="nav-item mb-2" v-if="isAuthenticated && userType=='Buyer' "><button class="btn  my-2 my-sm-0" style="background-color: #28a745;" @click="showModal=1">تسعيرة جديدة</button></li>
        <li class="nav-item mb-2" v-if="isAuthenticated"><button class="btn  my-2 my-sm-0 btn-secondary" @click="logout">تسجيل الخروج</button></li>




      </ul>
    </div>

  </nav>
  <div class="vld-overlay d-flex align-items-center" v-if="showModal>0">
    <div class="vld-background " style="    background: rgba(0, 0, 0, 0.3)!important;"></div>
    <transition name="fade" appear>

      <transition name="slide" appear>
        <div class="MyModal text-center container-fluid" v-if="showModal>0">
          <div class="text-right">
            <span @click="closeModal">
              <i class="close icon" style="font-size: 1.4em;"></i>
            </span>
          </div>

          <part-request v-if="showModal==1" />
          <app-address v-if="showModal==2" />
        </div>
      </transition>

    </transition>
  </div>
</div>
</template>

<script>
import partRequest from '../PartOrder/PartOrder.vue'
import appAddress from "../location.vue"



export default {

  data() {
    return {
    }
  },
  components: {
    partRequest,
    appAddress
  },
  computed: {
    smallScreen() {
      return this.$store.state.glopal.window.width > 500 ? false : true;
    },
    showModal: {
      get() {
      
        return this.$store.state.glopal.ModalState
      },
      set(newState) {
        this.$store.state.glopal.ModalState = newState;
        return
      }
    },
    isAuthenticated(){
      return this.$store.getters['authentication/isAuthenticated'];
    },
    userType(){
      return this.$store.getters['authentication/userType'];
    }

  },
  methods: {

    route() {
console.log('click');
      if (!this.isAuthenticated) {
        if (this.$router.currentRoute.fullPath == '/') {
          return;
        }
        this.$router.replace('/');
        return;
      } else if (this.isAuthenticated && this.userType == 'Seller') {
        if (this.$router.currentRoute.fullPath == '/Seller/Requests') {
          return;
        }
        this.$router.replace('/Seller/Requests');
        return;

      } else if (this.isAuthenticated && this.userType == 'Buyer') {
        if (this.$router.currentRoute.fullPath == '/Requests') {
          return;
        }
        this.$router.replace('/Requests');
        return;
      }
    },
    hideMenu() {
      $('navbar-collapse').collapse('hide')
    },
    logout() {
      this.$store.state.glopal.loginStepN=0;
      this.$store.dispatch('authentication/logout').then(()=>{
       
       this.$apolloProvider.defaultClient.resetStore();
        this.$store.state.glopal.isLoading=false;
        this.$router.replace("/login");
        //window.location.reload(true);
      })
    },
    closeModal() {
      this.$store.state.glopal.stepNo = 0;
      this.$store.state.glopal.ModalState = 0;
    },
    print(tex) {
      console.log(tex)
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
@media only screen and (max-width: 990px) {
  .bwj-nav {
    margin-right: -6rem;
    text-align: right !important;
  }

}

@media only screen and (max-width: 340px) {
  .bwj-nav {
    margin-right: -3rem;
  }

}

@media only screen and (min-width: 700px) {
  .aboutSetting {
    flex-grow: 15;
  }

}
</style>
