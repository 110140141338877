<template>
  <div class="container text-center">
    <form @submit.prevent="submit" class="text-center">
      <h3 class="my-5">دخل بيانات السيارة</h3>

      <div class="row input-group mb-3">
        <div class="col-12 p-0">
          <select
            id="manufacturers"
            :class="{ 'is-invalid': submitted && $v.selectedManufature.$error }"
            class="custom-select row"
            @change="ChangeManfcturare($event)"
            aria-describedby="manufactureRequired"
          >
            <option value="" disabled selected>الشركة المصنعة</option>
            <option
              v-for="(maker, index) in AllManuf"
              :data-value="index"
              :value="maker.name"
            >
              {{ maker.name }}
            </option>
            <option value="other">أخرى</option>
          </select>
        </div>
        <div class="col-12 mt-2 p-0" v-if="manufacturerNotListed">
          <input
            class="form-control"
            v-model="anotherManufacturer"
            placeholder="الشركة المصنعة"
            :class="{
              'is-invalid': submitted && $v.anotherManufacturer.$error,
            }"
            aria-describedby="manufactureRequired"
          />

          <div
            v-if="
              manufacturerNotListed == true
                ? $v.anotherManufacturer.$error
                : $v.selectedManufature.$error
            "
            class="invalid-feedback"
          >
            <span
              class="error"
              v-if="
                manufacturerNotListed == true
                  ? !$v.anotherManufacturer.required
                  : !$v.selectedManufature.required
              "
              id="manufactureRequired"
              >هذا الحقل مطلوب</span
            >
          </div>
        </div>

        <div
          v-if="
            manufacturerNotListed == true
              ? $v.anotherManufacturer.$error
              : $v.selectedManufature.$error
          "
          class="invalid-feedback"
        >
          <span
            class="error"
            v-if="
              manufacturerNotListed == true
                ? !$v.anotherManufacturer.required
                : !$v.selectedManufature.required
            "
            id="manufactureRequired"
            >هذا الحقل مطلوب</span
          >
        </div>
      </div>

      <div class="row input-group mb-3">
        <select
          v-if="!manufacturerNotListed"
          id="model"
          v-model="selectedModel"
          :class="{ 'is-invalid': submitted && $v.selectedModel.$error }"
          class="custom-select"
          aria-describedby="modelRequired"
        >
          <option value="" disabled selected>اسم السيارة</option>
          <option
            v-for="(model, index) in models"
            :data-value="index"
            :value="model"
          >
            {{ model }}
          </option>
        </select>

        <input
          class="form-control col-12"
          v-else
          v-model="selectedModel"
          placeholder="اسم السيارة"
          :class="{ 'is-invalid': submitted && $v.selectedModel.$error }"
          aria-describedby="modelRequired"
        />

        <div
          v-if="submitted && $v.selectedModel.$error"
          class="invalid-feedback"
        >
          <span
            class="error"
            v-if="!$v.selectedModel.required"
            id="modelRequired"
            >هذا الحقل مطلوب</span
          >
        </div>
      </div>

      <!-- <div class="row "> -->
      <!-- <input -->
      <!-- id="model" -->
      <!-- class="form-group bwj-w form-control" -->
      <!-- list="models" -->
      <!-- v-model.trim="$v.model.$model" -->
      <!-- @input="ChangeModel" -->
      <!-- placeholder="الموديل" -->
      <!-- type="text" -->
      <!-- :class="{ 'is-invalid': submitted && $v.model.$error }" -->
      <!-- aria-describedby="modelRequired" -->
      <!-- data-toggle="tooltip" -->
      <!-- data-placement="top" -->
      <!-- title="أكمل ادخال اسم سيارتك" -->
      <!-- /> -->

      <!-- <datalist id="models" v-if="modelsLoaded"> -->
      <!-- <option v-for="model in manModels">{{ -->
      <!-- model -->
      <!-- }}</option> -->
      <!-- </datalist> -->
      <!-- <div v-if="submitted && $v.model.$error" class="invalid-feedback"> -->
      <!-- <span class="error" v-if="!$v.model.required" id="modelRequired" -->
      <!-- >هذا الحقل مطلوب</span -->
      <!-- > -->
      <!-- </div> -->
      <!-- </div> -->

      <div class="row input-group mb-3">
        <input
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="4"
          class="form-control"
          v-model.trim="$v.year.$model"
          placeholder="سنة الصنع"
          type="number"
          :class="{ 'is-invalid': submitted && $v.year.$error }"
          aria-describedby="yearRequired"
        />
        <div v-if="submitted && $v.year.$error" class="invalid-feedback">
          <span class="error" v-if="!$v.year.required" id="yearRequired"
            >هذا الحقل مطلوب</span
          >
          <span class="error" v-if="!$v.year.between" id="yearNotValid"
            >سنة الصنع غير صحيحة</span
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, between, requiredIf } from "vuelidate/lib/validators";

import AllManuf from "../../assets/manufacture.json";

export default {
  data() {
    return {
      submitted: false,

      year: null,
      models: [],
      modelsLoaded: false,
      makes: [],
      AllManuf: AllManuf,
      selectedManufature: "",
      manufacturerNotListed: false,
      anotherManufacturer: "",
      selectedModel: "",
    };
  },
  validations: {
    selectedManufature: {
      required,
    },

    anotherManufacturer: {
      required: requiredIf(function (e) {
        console.log(e.manufacturerNotListed);
        return e.manufacturerNotListed ? true : false;
      }),
    },

    selectedModel: {
      required,
    },
    year: {
      required,
      between: between(1950, 2025),
    },
  },
  created() {
    for (var make = 0; make < AllManuf.length; make++) {
      let x = {
        name: AllManuf[make].name,
        models: AllManuf[make].models,
      };
      this.makes.push(x);
    }
  },

  methods: {
    ChangeManfcturare(e) {
      let val = e.target.value;
      console.log(e.target.value);
      if (val) {
        if (val == "other") {
          this.manufacturerNotListed = true;
        }
        this.selectedManufature = val;
      }

      const result = AllManuf.filter((res) => res.name == val).map(
        (ele) => ele.models
      );
      console.log(result[0]);
      this.modelsLoaded = true;
      this.models = result[0];
      return;
    },

    submmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.state.glopal.newRequest.manufacturer = this
          .manufacturerNotListed
          ? this.anotherManufacturer
          : this.selectedManufature;
        this.$store.state.glopal.newRequest.model = this.selectedModel;
        this.$store.state.glopal.newRequest.year = parseInt(this.year);
        this.$store.state.glopal.stepNo = 1;
        console.log(this.$store.state);
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
h1,
p {
  text-align: center;
}

p {
  color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
