
<template>
  <div class="site">
    <div class="vld-overlay d-flex align-items-center" :id="isLoading">
      <div class="vld-background"></div>
      <div class="d-flex justify-content-center">
        <img src="./img/wrench.svg" alt=" Loader" class="loader" />
      </div>
    </div>

    <app-header />

    <div
      class="container-fluid d-flex justify-content-center p-3"
      style="direction=ltr"
    >
      <div
        id="liveToast"
        v-if="alert.message"
        :class="`alert ${alert.type}`"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="10000"
      >
        <div>
          <img src="" class="rounded mr-2" alt="" />
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            @click="closeAlert"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body text-center">
          <h5 class="text-block text-wrap text-break">{{ alert.message }}</h5>
        </div>
      </div>
    </div>
    <router-view class="content"></router-view>

    <div id="loader-wrapper">
      <div id="loader"></div>

      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
    <!--  <div class="vld-parent">
         <loading :active.sync="isLoading"
         :can-cancel="false"

         :is-full-page="fullPage"
         >
  <img src="./img/wrench.svg" alt=" Loader" class="loader" />

                         </loading>


     </div>
     -->
    <div id="WAButton"></div>

    <app-footer />
  </div>
</template>

<script>
import Header from "./components/header/header.vue";
import Footer from "./components/Footer/Footer.vue";
//import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    //this.$store.dispatch('glopal/autoLogin');
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      slides: 7,

      fullPage: true,
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    isLoading: function () {
      console.log(this.$store.getters["glopal/isLoading"]);
      if (this.$store.getters["glopal/isLoading"]) {
        return "show";
      } else {
        //  console.log(this.$store.getters.isLoading);

        return "hide";
      }
    },
  },
  methods: {
    handleResize() {
      console.log("resize");
      let width = window.innerWidth;
      let height = window.innerHeight;
      this.$store.commit("glopal/resize", { width, height });
      //  .window.width = window.innerWidth;
      //  this.$store.state.window.height = window.innerHeight;
    },

    closeAlert() {
      this.$store.commit("alert/clear");
    },
  },
};
</script>

<style>
@font-face {
  font-display: swap;
  font-family: "Tajawal";
  src: local("Tajawal"), url("./fonts/Tajawal-Medium.ttf") format("truetype");
}
.text-block {
  white-space: pre;
}
.loader {
  width: 5rem;
  height: 5rem;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 3s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

  #loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #ffff;
    z-index: 1000;
  }
}

.vld-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

#show {
  display: flex !important;
}

#hide {
  display: none !important;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* general website style */
html {
  height: 100%;
}

body {
  font-family: Tajawal;
  font-style: normal;
  direction: rtl;
  height: 100%;
}

.content {
  flex: 1;
}

#Page-container {
  position: relative;
  min-height: 100vh;
}

h3,
h5,
a {
  color: rgba(9, 88, 137, 1);
}

a:hover {
  color: rgba(9, 88, 137, 1);
}

#content-wrap {
  /* padding-bottom: 2.5rem;     Footer height */
}

#footer {
  position: absolute;

  width: 100%;
  /* Footer height */
}

.container {
  width: 100%;

  z-index: 1;
}

/* navbar style */
.navbarSupportedContent a {
  font-weight: bold;
  font-size: 18px;
  color: rgba(9, 88, 137, 1) !important;
}

.navbarSupportedContent li {
  margin-left: 1rem;
}

.navbarSupportedContent button {
  font-weight: bold;
  background-color: rgba(9, 88, 137, 1);
  color: white;
}

/* intro style */

.bwj-btn {
  font-weight: bold;
  background-color: rgba(9, 88, 137, 1);
  color: white;
}

#SginIn {
  position: static;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.intro-text {
  text-align: right;
  width: 33rem;
  height: 20rem;
}

.intro-text h1 {
  color: rgba(9, 88, 137, 1);
  font-size: 2rem;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

secound-intro-text {
  font-size: 1.2rem;
  color: darkcyan;
  margin-top: 2rem;
  margin-right: 1rem;
}

/* part order section style */

/* progressbar style */
.progressbar {
  counter-reset: step;
  height: 100%;
  width: 100%;
  display: inline-block;
  margin-top: 2rem;
}

.progressbar li.active:after {
  background: #a5affb;
  border-color: white;
  color: white;
}

.progressbar li.active:before {
  background: #a5affb;
  color: white;
  border: 2px solid white;
}

.progressbar li {
  float: right;

  width: 25%;
  position: relative;
  text-align: center;
  list-style-type: none;
  color: rgba(9, 88, 137, 1);
  font-size: 1.2rem;
  font-weight: bold;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;

  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: rgba(9, 88, 137, 1);
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.3rem;
  background: #ecebed;
  top: 0.8rem;
  left: -50%;
  z-index: -1;
}

.progressbar li:last-child:after {
  content: none;
}

/* carousel style */
.carousel-indicators li {
  border-radius: 50%;
  height: 0.9rem;
  width: 0.9rem;
  margin: 1rem;
  background-color: rgba(9, 88, 137, 1) !important;
}

.carousel-indicators {
  margin-right: auto;
  margin-left: auto;
}

.carousel-home-images {
  width: 100%;
  height: 100%;
  text-align: center !important;
  padding-top: 2rem;
}

/* parts order form style */
.order-part-form {
  background-color: rgba(255, 255, 255, 0.671);
  border-radius: 9.5px 9.5px 9.5px 9.5px;
  border: 1px solid rgba(0, 0, 0, 1);
  overflow: hidden;
  text-align: right;

  width: 100%;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 3px 3px;
  cursor: pointer;
  border-radius: 0.2rem;
}

/* Bawaji slagon style */
#Bawaji_slagon_footer {
  text-align: center;
  font-family: Tajawal;
  font-style: normal;
  font-weight: Bold;
  font-size: 2rem;
  color: #707070;
}

/* verfication CSS */
#wrapper {
  font-size: 1.5rem;
  text-align: center;
}

/* Table  */

thead tr {
  background-color: rgba(9, 88, 137, 1) !important;
  color: white !important;
}

tbody {
  background-color: rgba(140, 140, 140, 0.271) !important;
}

table {
  border-collapse: collapse !important;
  border-radius: 0.4rem !important;
  overflow: hidden !important;
}

#wrapper #dialog #form {
  max-width: 24rem;
  margin: 5rem auto 0;
}

#wrapper #dialog #form input {
  margin-right: 1rem;
  text-align: center;
  line-height: 80px;
  font-size: 50px;
  border: solid 1px #ccc;
  box-shadow: 0 0 5px #ccc inset;
  outline: none;
  width: 20%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
}

#wrapper #dialog #form input:focus {
  border-color: purple;
  box-shadow: 0 0 5px purple inset;
}

#wrapper #dialog #form input::-moz-selection {
  background: transparent;
}

#wrapper #dialog #form input::selection {
  background: transparent;
}

#wrapper #dialog div {
  position: relative;
  z-index: 1;
}

#myDiv {
  display: none;
  text-align: center;
}

.MyModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  width: 100%;
  max-width: 550px;
  background-color: #fff;
  border-radius: 16px;

  padding: 25px;

  h1 {
    color: #222;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 15px;
  }

  p {
    color: #666;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-50%) translateX(100vw);
}

.bw-span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#liveToast {
  background-color: rgba(140, 140, 140, 0.27);
}

@media only screen and (max-width: 576px) {
  .vgt-compact td span {
    float: left;
    padding-left: 1rem;
  }

  .vgt-compact td span button {
    position: relative;
    left: 80%;
  }

  .vgt-compact td:before {
    float: none !important;
    padding-right: 10px !important;
    text-align: right !important;
  }

  .footer__navigation__page-info {
    margin: 0 0;
  }
  .vgt-right-align {
    display: flex !important;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 576px) {
  .vgt-table th.sortable button:before {
    right: unset !important;
    left: 6px !important;
  }

  .vgt-table th.sortable button:after {
    right: unset !important;
    left: 6px !important;
  }

  .vgt-wrap__footer .footer__row-count__select {
    margin-top: -5px !important;
  }

  .vgt-wrap__footer .footer__row-count::after {
    top: -23px !important;
    display: unset !important;
    position: relative !important;
  }

  .vgt-wrap__footer .footer__row-count {
    height: 10px !important;
    margin-left: 25px;
    margin-top: 5px;
  }
}
.action-class::before {
  content: none !important;
}
.action-class {
  justify-content: center !important;
  justify-content: center !important;
  justify-content: center !important;
}
.error {
  display: block;
}
.carousel-indicators {
  bottom: -4rem !important;
}

img {
  max-width: 100%;
  object-fit: contain;
}
</style>
