import gql from "graphql-tag";

export const GET_USER_REQUSTS = gql`
  query getUserRequests($RequestsFilter: RequestInput!) {
    getUserRequests(requestsFilter: $RequestsFilter) {
      remainingRequests
      requests {
        _id
        name
        manufacturer
        model
        year
        offers {
          _id
        }
        expiresAt
      }
    }
  }
`;
export const GET_ALL_REQUSTS = gql`
  query getRequests($RequestsFilter: RequestInput!) {
    getRequests(requestsFilter: $RequestsFilter) {
      remainingRequests
      requests { _id
      requester
      name
      manufacturer
      model
      year
      category
      description
      images
      offers {
        _id
      }
      active
      location
      hasOwnOffer
      lowestOffer
      expiresAt
    }
    }
  }
`;
export const GET_FULL_REQUST = gql`
  query getRequest($RequestID: ID!) {
    getRequest(requestId: $RequestID) {
      _id
      requester
      name
      manufacturer
      model
      year
      category
      description
      images
      offers {
        _id
        offerAmount
        shippingAmount
        acceptsCod
        address {
          _id
          district
          city
        }
      }
      active
      acceptedOffer
      location
      lowestOffer
    }
  }
`;

export const GET_OFFERS = gql`
  query getUserOffers {
    getUserOffers {
      _id
      requester
      location
      name
      manufacturer
      model
      year
      category
      images
      offers {
        _id
        seller
        offerAmount
        shippingAmount
        acceptsCod
        acceptsCollect

        images
      }
      active
      acceptedOffer
    }
  }
`;

export const GET_FULL_OFFER = gql`
  query getOffer($RequestID: ID!, $OfferID: ID!) {
    getOffer(requestId: $RequestID, offerId: $OfferID) {
      _id
      seller
      offerAmount
      shippingAmount
      acceptsCod
      acceptsCollect
      images
      insurancePeriod
      comment
      codCharge
      returnPeriod
      replacePeriod
      deliveryPeriod
    }
  }
`;

export const GET_ORDERS = gql`
  query {
    getOrders {
      _id
      status
      requester {
        _id

        firstName
        lastName
      }
      seller {
        _id

        firstName
        lastName
      }
      shipFrom {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      shipTo {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      part {
        name
        manufacturer
        model
        year
        category
        images
        description
      }
      partPrice
      shippingPrice
      cod
      pickUp
      createdAt
      codCharge
    }
  }
`;

export const GET_FUUL_ORDER = gql`
  query getOrder($OrderID: ID!) {
    getOrder(orderId: $OrderID) {
      _id
      status
      requester {
        _id

        firstName
        lastName
      }
      seller {
        _id
        
        firstName
        lastName
      }
      shipFrom {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      shipTo {
        _id
        buildingNo
        streetName
        district
        addNo
        postalCode
        city
      }
      part {
        name
        manufacturer
        model
        year
        category
        images
        description
      }
      partPrice
      shippingPrice
      cod
      pickUp
      createdAt
      codCharge
      sellerImages
      insurancePeriod
returnPeriod
replacePeriod
deliveryPeriod
sellerComment
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      _id
      phone
      firstName
      lastName
      isActive
    }
  }
`;
export const GET_ADDRESS = gql`
  query {
    getAddress {
      _id
      buildingNo
      streetName
      district
      addNo
      postalCode
      city
    }
  }
`;
export const INIT_PAYMENT = gql`
  query initPayment(
    $RequestId: ID!
    $AddressId: ID
    $OfferId: ID!
    $pickUp: Boolean!
    $otp: Int
  ) {
    initPayment(
      requestId: $RequestId
      addressId: $AddressId
      offerId: $OfferId

      pickUp: $pickUp
      otp: $otp
    )
  }
`;