<template>
  <div class="container d-flex justify-content-center">
    <div v-if="stepNumber == 0">
      <form @submit.prevent="submmit" class="">
        <div class="text-center">
          <h3 class="my-5">دخل رقمك</h3>


          <div class="form-group container-fluid">
            <div class="input-group input-group-lg" style="direction: ltr">
              <div class="input-group-prepend ">

                <select class="form-control-l login-select" id="exampleFormControlSelect1" v-model="selectedCode">
                  <option class="input-group-text" id="inputGroup-sizing-lg" v-for="countryCode in countriesCodes"
                    :value="countryCode.dial_code">{{countryCode.dial_code}}</option>

                </select>

              </div>

              <input autofocus name="phoneNumber" type="tel" class="form-control"
                aria-describedby="inputGroup-sizing-lg" v-model.trim="number" placeholder="5xxxxxxxx"
                :class="{ 'is-invalid': submitted && $v.userPhoneNumber.$error  || $v.number.$error  }" />
              <div v-if="submitted && $v.userPhoneNumber.$error || $v.number.$error " class="invalid-feedback">
                <span class="error" v-if="!$v.number.required">هذا الحقل مطلوب</span>
                <!-- <span
                  class="error"
                  v-if="!$v.number.minLength || !$v.number.maxLength"
                  >يجب ان يكون الرقم من تسع خانات</span
                > -->
                <span class="error" v-if="!$v.userPhoneNumber.phoneNumber">يجب ان يكون الرقم صحيحاً</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div v-if="stepNumber == 1" style="direction: ltr">
      <h3 class="mb-4">دخل رمز التحقق</h3>

      <div style="display: flex; flex-direction: row">
        <v-otp-input ref="otpInput" input-classes="otp-input" separator=" " :num-inputs="4" :should-auto-focus="true"
          :is-input-num="true" @on-complete="veiwOTP" />
      </div>
    </div>
    <transition name="fade" appear>
      <div class="myModal-overlay" v-if="showModal == 2" @click="closeModal"></div>
    </transition>
    <transition name="slide" appear>
      <div class="MyModal" v-if="showModal == 2">
        <app-address />
      </div>
    </transition>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import adress from "../location.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

import countriesCodes from '../../assets/countryListCodes.json';
export default {
  data() {
    return {
      submitted: false,
      number: "",
      activateCode: null,
      otp: null,
      countriesCodes: countriesCodes,
      selectedCode: "+966",
    };
  },
  created() {
    // reset login status
    this.$store.dispatch("authentication/logout");
    if (this.$route.query.p) {
      console.log(this.$route.query);
      let user = {
        phone: "+" + this.$route.query.p,
        otp: this.$route.query.o,
      };

      this.login(user);
      console.log(user);
    }
  },
  beforeDestroy() {
    console.log("hook");
    this.$store.state.glopal.loginStepN = 0;
  },
  validations() {
    return {
      number: {
        required
      },
      userPhoneNumber: {

        phoneNumber: this.phoneNumber,

      }
    }
  },
  components: {
    "app-address": adress,

    "v-otp-input": OtpInput,
  },
  methods: {
    phoneNumber() {

      var re = /^\+\d{8,15}$/;
      return re.test(this.selectedCode + this.number);

    },

    veiwOTP(e) {
      this.$store.commit("glopal/isLoading", true);
      console.log(typeof e);
      this.otp = e;
      let user = {
        phone: this.selectedCode + this.number,
        otp: this.otp,
      };
      this.login(user);
      console.log(user);
    },
    login(user) {
      this.$store
        .dispatch("authentication/login", user)
        .then((user) => {
          if (
            this.$route.query.redirect &&
            this.$store.getters["authentication/isAuthenticated"]
          ) {
            console.log(this.$route);
            this.$router.push(this.$route.query.redirect);
          } else if (user == "Seller") {
            this.$router.replace("/seller/requests");
          } else if (this.$store.state.glopal.stepNo == 2) {
            this.$store.state.glopal.ModalState = 2;
          } else {
            console.log(this.$router.currentRoute);
            if (this.$router.currentRoute.path != "/Requests") {
              this.$router.replace("/Requests");
            }
          }

          if (this.$store.state.glopal.stepNo != 2) {
            console.log("there");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("glopal/isLoading", false);
          return e;
        });

      // if (this.$route.name != "Login") {
      //  console.log(this.$route.name);
      // this.$store.state.glopal.loginStepN = 0;
      // this.$store.commit("glopal/isLoading", false);
      // }
    },
    closeModal() {
      this.$store.state.glopal.ModalState = 0;
    },
    phoneVaild(no) {
      let firstDigit = (no + "")[0];

      console.log(firstDigit);
    },

    async submmit() {
      if (this.stepNumber == 0) {
        console.log(this.number);
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
          if (this.$store.state.glopal.stepNo == 2) {
            window.open(
              "https://api.whatsapp.com/send?phone=966562240373&text=%D8%B1%D9%85%D8%B2%20%D8%A7%D9%84%D8%AA%D8%AD%D9%82%D9%82%20%D9%84%D8%A7%D9%83%D9%85%D8%A7%D9%84%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8",
              "_blank"
            );
          } else {
            window.open(
              "https://api.whatsapp.com/send?phone=966562240373&text=%D8%B1%D9%85%D8%B2%20%D8%A7%D9%84%D8%AA%D8%AD%D9%82%D9%82%20%D9%84%D8%AA%D8%B3%D8%AC%D9%8A%D9%84%20%D8%A7%D9%84%D8%AF%D8%AE%D9%88%D9%84",
              "_blank"
            );
          }

          this.$store.state.glopal.loginStepN = 1;
          console.log(this.$store.state.glopal.loginStepN);
        }
      }
    },
    handleOnChange() {
      if (this.number.length > 9) {
        this.number = this.number.slice(0, 9);
      }
      return this.$emit("on-change", this.model);
    },
    handleOnKeyDown(event) {
      // Only allow characters 0-9, DEL, Backspace and Pasting
      const keyEvent = event || window.event;
      const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
      if (
        this.isCodeNumeric(charCode) ||
        charCode === 8 ||
        charCode === 86 ||
        charCode === 46 ||
        charCode === 13
      ) {
        this.$emit("on-keydown", event);
      } else {
        keyEvent.preventDefault();
      }
    },
    isCodeNumeric(charCode) {
      // numeric keys and numpad keys
      return (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105)
      );
    },
  },
  computed: {
    stepNumber() {
      return this.$store.getters["glopal/loginStepN"];
    },
    loading() {
      return this.$store.getters["glopal/loading"];
    },
    showModal() {
      return this.$store.state.glopal.ModalState;
    },
    getUserAddress() {
      return this.$store.state.glopal.address;
    },
  },
};
</script>

<style>
.signin-form {
  width: 400px;
  margin: 30px auto;
  border: 1px solid #eee;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}

.input {
  margin: 10px auto;
}

.input label {
  display: block;
  color: #4e4e4e;
  margin-bottom: 6px;
}

.input input {
  font: inherit;
  width: 100%;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.input input:focus {
  outline: none;
  border: 1px solid #521751;
  background-color: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp-input {
  width: 4rem;
  height: 5rem;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.login-select {
  background: #e9ecef;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-radius: 0.25rem;
  border: 1px solid #ced4da !important;
  color: #495057 !important;
}
</style>
