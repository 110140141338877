import Vue from "vue";
import App from "./App.vue";
import { store } from "./_store";
import { router } from "./_helpers";
import Vuelidate from "vuelidate";
import { createProvider } from "./_helpers";


Vue.use(Vuelidate);
Vue.prototype.$SginIn = false;

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
