import AllManuf from "../assets/manufacture.json";
export function getModels() {
  let makes = AllManuf.map((x, index) => {
    let man = {
      id: index.toString(),
      label: x.name,

      children: [],
    };
    for (let modelIndex = 0; modelIndex < x["models"].length; modelIndex++) {
      man.children.push({
        id: index + "-" + (modelIndex + 1),
        label: x["models"][modelIndex],
      });
    }

    return man;
  });

  return makes;
}
export function getFillterdModels(ids) {
  let ModelsArr = getModels();

  let filterdManufacture = [];
  let filterdModels = [];
  for (const key in ids) {
    let ourSplit = ids[key].split("-");

    if (ourSplit.length == 1) {
      filterdManufacture.push(ModelsArr[parseInt(ourSplit[0])].label);

      filterdModels.push(
        ...getChildsLabels(ModelsArr[parseInt(ourSplit[0])].children)
      );
    } else {
      console.log(ourSplit);
      filterdModels.push(
        ModelsArr[parseInt(ourSplit[0])].children[ourSplit[1]-1].label
      );
      console.log(ModelsArr);
    }
  }

  return {
  
    model: filterdModels,
  };
}
// manufacturer: filterdManufacture,
function getChildsLabels(childs) {
  let childsArray = [];
  for (const key in childs) {
    childsArray.push(childs[key].label);
  }
  return childsArray;
}
