import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./alert.module";
import { authentication } from "./authentication.module";
import { glopal } from "./glopal.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    authentication,
    glopal,
  },
});
