<template>
  <div
    class="p-4 order-part-form"
    :class="!isAuthenticated ? 'order-part-form' : ''"
    style="border: none !important"
  >
    <app-car-details
      v-if="this.$store.state.glopal.stepNo == 0"
    ></app-car-details>
    <app-part-details
      v-if="this.$store.state.glopal.stepNo == 1"
    ></app-part-details>
    <app-signin v-if="this.$store.state.glopal.stepNo == 2"></app-signin>
    <div class="mt-4 d-flex justify-content-center">
      <div>
        <button
          type="submit"
          class="form-control btn bwj-btn btn-lg"
          name="submit"
          style="width: 7rem"
          @click="nextFunction"
        >
          اﻟﺘﺎﻟﻲ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CarDetails from "./CarDetails.vue";
import PartDetails from "./PartDetails.vue";
import SignIn from "../auth/signin.vue";

export default {
  data() {
    return {};
  },
  components: {
    "app-car-details": CarDetails,
    "app-part-details": PartDetails,
    "app-signin": SignIn,
  },

  methods: {
    nextFunction() {
      this.$children[0].submmit();
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["authentication/isAuthenticated"];
    },
  },
};
</script>

<style scoped></style>
