import jwt_decode from "jwt-decode";
import {onLogin} from './vue-apollo'

export function setToken(token) {
  let decodedToken = jwt_decode(token.data.token);
  onLogin(token.data.token);
  //if (token.data.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    let userInformation={
      userType:decodedToken.userType,
      token:token.data.token,
      expirtionDate:decodedToken.exp,
      isAuth:decodedToken.isAuth
    }
    localStorage.setItem('user', JSON.stringify(userInformation));
  
  console.log(decodedToken);
return  Promise.resolve(userInformation);
};
