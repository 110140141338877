import Vue from "vue";
import VueRouter from "vue-router";
import {
  store
} from "../_store/index.js";

Vue.use(VueRouter);
export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
    path: "/about",
    name: "About",
    component: () =>
      import( /* webpackChunkName: "About" */ "../views/public/About.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import( /* webpackChunkName: "Home" */ "../views/Home.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import( /* webpackChunkName: "Login" */ "../views/Login.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/requests",
    name: "UserRequests",
    component: () =>
      import(
        /* webpackChunkName: "user UserRequests" */
        "../views/UserRequests.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  {
    path: "/offers/:requestID",
    name: "UserOffers",
    component: () =>
      import( /* webpackChunkName: "user Offers" */ "../views/Offers.vue"),

    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  {
    path: "/offers/offerDetails/:requestID/:offerID",
    name: "UserOfferDetails",
    component: () =>
      import(
        /* webpackChunkName: "user Offer details" */
        "../views/OfferDetails.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  {
    path: "/orders",
    name: "UserOrders",
    component: () =>
      import( /* webpackChunkName: "user orders" */ "../views/orders.vue"),
    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  // {
  //   path: '/OfferDetails/:OfferID',
  //   name: 'OfferDetails',
  //   component: OfferDetails,
  //       beforeEnter: AuthGuard
  // },
  {
    path: "/orderDetails/:orederID",
    name: "UserOrderDetails",
    component: () =>
      import(
        /* webpackChunkName: "user OrderDetails" */
        "../views/OrderDetails.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  {
    path: "/completeOrder/:requestID/:offerID",
    name: "UserCompleteOrder",
    component: () =>
      import(
        /* webpackChunkName: "user CompleteOrderPage" */
        "../views/CompleteOrderPage/index.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  {
    path: "/confirmPayment/:offerID/:addressID/:pickUp",
    name: "UserOrderStatus",
    component: () =>
      import(
        /* webpackChunkName: "user CompleteOrderPage" */
        "../views/CompleteOrderPage/orderConfirmation.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Buyer"],
    },
  },
  {
    path: "/seller/signUp",
    name: "SellerSignUp",
    component: () =>
      import(
        /* webpackChunkName: "seller signUp" */
        "../components/seller/signUp.vue"
      ),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/seller/requests",
    name: "SellerRequests",
    component: () =>
      import(
        /* webpackChunkName: "seller Requests" */
        "../components/seller/Requests.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Seller", "Administrator"],
    },
  },
  {
    path: "/seller/offers",
    name: "SellerOffers",
    component: () =>
      import(
        /* webpackChunkName: "seller offer" */
        "../components/seller/Offers.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Seller"],
    },
  },
  {
    path: "/seller/orders",
    name: "SellerOrders",
    component: () =>
      import(
        /* webpackChunkName: "seller Orders" */
        "../components/seller/Orders.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Seller"],
    },
  },
  {
    path: "/seller/fullRequest/:requestID",
    name: "SellerFullRequest",
    component: () =>
      import(
        /* webpackChunkName: "seller FullRequest" */
        "../components/seller/FullRequest.vue"
      ),

    meta: {
      requireAuth: true,
      userType: ["Seller"],
    },
  },
  {
    path: "/seller/fullOffer/:requestID/:offerID",
    name: "SellerFullOffer",
    component: () =>
      import(
        /* webpackChunkName: "seller FullOffer" */
        "../components/seller/FullOffer.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Seller"],
    },
  },
  {
    path: "/seller/fullOrder/:orderID",
    name: "SellerFullOrder",
    component: () =>
      import(
        /* webpackChunkName: "seller FullOffer" */
        "../components/seller/fullOrder.vue"
      ),
    meta: {
      requireAuth: true,
      userType: ["Seller"],
    },
  },
  {
    path: "/canclePage",
    name: "CanclePolicy",
    component: () =>
      import(
        /* webpackChunkName: "CanclePolicy" */
        "../views/public/CanclePolicy.vue"
      ),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */
        "../views/public/PrivacyPolicy.vue"
      ),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */
        "../views/public/contactus.vue"
      ),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/commonquestions",
    name: "CommonQuestions",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */
        "../views/public/commonquestions.vue"
      ),
    meta: {
      requireAuth: false,
    },
  },
  // {
  //   path: "/admin/addUser",
  //   name: "AddUser",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "PrivacyPolicy" */
  //       "../components/Admin/AddSeller.vue"
  //     ),
  //   meta: {
  //     requireAuth: true,
  //     userType: ["Administrator"],
  //   },
  // },
  {
    path: "/shippingAndpayment",
    name: "ShippingAndPayment",
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */
        "../views/public/shippingandpayment.vue"
      ),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/404",
    name: "404page",
    component: () =>
      import( /* webpackChunkName: "404page" */ "../views/public/404page.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "*",
    redirect: "/404"
  },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
});

if (router.mode === "history") {
  router.history.getCurrentLocation = function () {
    let path = window.location.pathname;
    let base = router.history.base;

    // Removes base from path (case-insensitive)
    if (base && path.toLowerCase().indexOf(base.toLowerCase()) === 0) {
      path = path.slice(base.length);
    }

    return (path || "/") + window.location.search + window.location.hash;
  };
}

router.beforeEach(async (to, from, next) => {
  store.dispatch("alert/clear");
  const isAuthenticated = store.getters["authentication/isAuthenticated"];

  if (to.meta.requireAuth) {

    if (to.query.p) {

      let user = {
        phone: "+" + to.query.p,
        otp: to.query.o,
      };
      await store.dispatch("authentication/login", user);
    }

    if (!isAuthenticated) {
      console.log("logout");
      store.dispatch("authentication/logout");
      router.push({
        name: "Login",
        query: {
          redirect: to.path
        }
      });
    } else {
      if (to.meta.userType.includes(store.getters["authentication/userType"])) {
        next();
      } else {
        router.replace("/404");
      }

    }
  } else {

    next();
  }
});