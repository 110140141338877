import Vue from "vue";
import VueApollo from "vue-apollo";
import { store } from "../_store";
import {
  createApolloClient,
  restartWebsockets,
} from "vue-cli-plugin-apollo/graphql-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link"; // add Observable

// console.log(this);
// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint =
  process.env.NODE_ENV == "development"
    ? " http://localhost:3000/graphql"
    : "https://scrapiz.com/graphql";
// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

const link = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // User access token has expired
  if (graphQLErrors) {
    store.dispatch("alert/error", graphQLErrors[0].message);
    // We assume we have both tokens needed to run the async request
    console.log(graphQLErrors);
  }
  if (networkError) {
    if (
      networkError.response.status == 401 ||
      networkError.response.status == 400
    ) {
      return new Observable((observer) => {
        console.log(observer);
        store
          .dispatch("authentication/refreshToken")
          .then((refreshResponse) => {
            console.log(refreshResponse);
            operation.setContext(({ headers = {} }) => ({
              headers: {
                authorization: `Bearer ${refreshResponse}` || null,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            console.log(subscriber);
            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });
    }
    console.log(`[Network error]: ${networkError}`);
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers

  store.commit("glopal/isLoading", true);
  return forward(operation);
});
// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: new ApolloLink.from([link, authMiddleware]),
  // Override default cache
  cache: new InMemoryCache(),

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      console.error(error);
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  console.log(token);

  // if (ApolloClient.wsClient) restartWebsockets(ApolloClient.wsClient);
  // try {
  // await ApolloClient.resetStore();
  // } catch (e) {
  // console.log("%cError on cache reset (login)", "color: orange;", e.message);
  // }
}

// Manually call this when user log out
export async function onLogout() {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }

  // if (ApolloClient.wsClient) restartWebsockets(ApolloClient.wsClient);
  // try {
  // await ApolloClient.resetStore();
  // } catch (e) {

  // console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  // }
}

// const refreshToken= new TokenRefreshLink({
//     isTokenValidOrUndefined: () => !isTokenExpired() || typeof getAccessToken() !== 'string',
//     fetchAccessToken: () => {
//       return fetch(getEndpoint('getAccessTokenPath'), {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${getAccessToken()}`,
//           'refresh-token': getRefreshToken()
//         }
//       });
//     },
//     handleFetch: accessToken => {
//       const accessTokenDecrypted = jwtDecode(accessToken);
//       setAccessToken(accessToken);
//       setExpiresIn(parseExp(accessTokenDecrypted.exp).toString());
//     },
//     handleResponse: (operation, accessTokenField) => response => {
//       // here you can parse response, handle errors, prepare returned token to
//       // further operations
//
//       // returned object should be like this:
//       // {
//       //    access_token: 'token string here'
//       // }
//     },
//     handleError: err => {
//     	// full control over handling token fetch Error
//     	console.warn('Your refresh token is invalid. Try to relogin');
//     	console.error(err);
//
//     	// your custom action here
//     	user.logout();
//     }
//   });
// const retryIf = (error, operation) => {
//   console.log(localStorage.getItem(AUTH_TOKEN));
//
//   console.log(error);
//   const doNotRetryCodes = [413, 400];
//   return !!error && !doNotRetryCodes.includes(error.statusCode);
// };
// const retry=new RetryLink({
//   delay: {
//     initial: 300,
//     max: Infinity,
//     jitter: true
//   },
//   attempts: {
//     max: 5,
//     retryIf
//   }
// });
//
// const handelErorr = onError(({ graphQLErrors, networkError}) => {
//
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//       ),
//     );
//
//   if (networkError){
// if (networkError.response.status==500||networkError.response.status==401) {
//
//   userService.refreshToken()
//   .then((decodedToken)=>{
//     console.log(decodedToken);
//
//     return ;
//   }).catch((error) => {
//     console.log(error)
//
//     return ;
//   });
// }
//     console.log(`[Network error]: ${networkError}`);
// }
// });
